var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.idTable + "_container " } },
    [
      _c(
        "div",
        {
          staticClass: "table_scroll_duplicate",
          attrs: { id: _vm.idTable + "_scrollDuplicate" },
        },
        [_c("div", { attrs: { id: _vm.idTable + "_dummy" } })]
      ),
      _c(
        "v-data-table",
        {
          attrs: {
            id: _vm.idTable,
            loading: _vm.loadingTable,
            headers: _vm.headers,
            options: _vm.pagination,
            items: _vm.items,
            page: _vm.pagination.page,
            "server-items-length": _vm.totalItems,
            "footer-props": {
              disablePagination: _vm.items.length === 0 || _vm.loadingTable,
              itemsPerPageOptions:
                _vm.totalItems < 50
                  ? [
                      { value: 50, text: "50" },
                      { value: 100, text: "100", disabled: true },
                      { value: 250, text: "250", disabled: true },
                    ]
                  : _vm.totalItems < 100
                  ? [
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                      { value: 250, text: "250", disabled: true },
                    ]
                  : [
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                      { value: 250, text: "250" },
                    ],
              disableItemsPerPage: _vm.items.length === 0 || _vm.loadingTable,
              itemsPerPageText: `${_vm.tableTitle} por página: `,
              expandIcon: "mdi-dots-horizontal",
            },
            "no-data-text": `Não há registros de ${_vm.tableTitle} a serem exibidos.`,
          },
          on: {
            "update:options": function ($event) {
              _vm.pagination = $event
            },
            "current-items": _vm.onTableInput,
          },
          scopedSlots: _vm._u([
            {
              key: "footer.page-text",
              fn: function (items) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                      " - " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                      " de " +
                      _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                      " " +
                      _vm._s(_vm.tableTitle) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center",
                      style: _vm.getTableWidth,
                      attrs: { id: "noDataText" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "Não há registros de " +
                            _vm._s(_vm.tableTitle) +
                            " a serem exibidos."
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.id_column",
              fn: function ({ item }) {
                return [_vm._v(" " + _vm._s(item.id_column) + " ")]
              },
            },
            {
              key: "item.action",
              fn: function ({ item }) {
                return [
                  _c(
                    "span",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ag-btn-primary",
                          attrs: {
                            icon: "",
                            title: "Visualizar",
                            id: "Btn_visualizar",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "text-button",
                              attrs: { to: `/eSocial/rubricas/${item.id}` },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" visibility "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.loadingTable
            ? _c("template", { slot: "body" }, [
                _c(
                  "tr",
                  _vm._l(_vm.headers, function (loadItem) {
                    return _c(
                      "td",
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "loaderCss",
                          attrs: { "max-width": loadItem.width },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }