<template>
    <div :id="idTable + '_container '">
      <div :id="idTable + '_scrollDuplicate'" class="table_scroll_duplicate">
        <div :id="idTable + '_dummy'"></div>
      </div>
      <v-data-table
        :id="idTable"
        :loading="loadingTable"
        :headers="headers"
        :options.sync="pagination"
        :items="items"
        :page="pagination.page"
        :server-items-length="totalItems"
        :footer-props="{
          disablePagination: items.length === 0 || loadingTable,
          itemsPerPageOptions:
            totalItems < 50
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100', disabled: true },
                  { value: 250, text: '250', disabled: true },
                ]
              : totalItems < 100
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250', disabled: true },
                ]
              : [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250' },
                ],
          disableItemsPerPage: items.length === 0 || loadingTable,
          itemsPerPageText: `${tableTitle} por página: `,
          expandIcon: 'mdi-dots-horizontal',
        }"
        :no-data-text="`Não há registros de ${tableTitle} a serem exibidos.`"
        @current-items="onTableInput"
      >
        <template v-slot:footer.page-text="items">
          {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
          {{ loadingTable ? 0 : items.itemsLength }} {{ tableTitle }}
        </template>
        <template slot="body" v-if="loadingTable">
          <tr>
            <td v-for="loadItem in headers">
              <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
            </td>
          </tr>
        </template>
  
        <template v-slot:no-data>
          <div id="noDataText" class="d-flex justify-center" :style="getTableWidth">
            <p>Não há registros de {{ tableTitle }} a serem exibidos.</p>
          </div>
        </template>
  
        <template v-slot:item.id_column="{ item }">
          {{ item.id_column }}
        </template>

        <template v-slot:item.action="{ item }">
          <span>
            <v-btn class="ag-btn-primary" icon title="Visualizar" id="Btn_visualizar">
              <router-link class="text-button" :to="`/eSocial/rubricas/${item.id}`">
                <v-icon small> visibility </v-icon>
              </router-link>
            </v-btn>
          </span>
        </template>

      </v-data-table>
    </div>
  </template>
  
  <script>
  import { uniqueId } from 'lodash';
  import TableMixin from "@/mixins/Table.js"

  export default {
    name: 'TableCat',
  
    components: {
      AGTooltip: () => import('../../commons/AGTooltip.vue'),
    },
    mixins: [TableMixin],
  
    props: {
      idTable: {
        type: String,
        default: uniqueId('table_'),
      },
      loadingTable: {
        type: Boolean,
        default: false,
      },
      headers: {
        type: Array,
        required: true,
      },
      paginationFilter: {
        type: Object,
        default: () => {
          return {
            itemsPerPage: 50,
            page: 1,
            sortBy: [],
          };
        },
      },
      items: {
        type: Array,
        default: () => {
          return [];
        },
      },
      totalItems: {
        type: Number,
        required: false,
      },
      footerProps: {
        type: Object,
        default: () => {
          return {};
        },
      },
      tableTitle: {
        type: String,
        required: true,
      },
    },
    computed: {
      pagination: {
        get: function () {
          return this.paginationFilter;
        },
        set: function (value) {
          this.$emit('setPagination', value);
        },
      },
    },

  };
  </script>
  
  <style lang="scss" scoped>
  @import './src/design/components/table.scss';
  @import './src/design/components/tooltip.scss';
  
  </style>